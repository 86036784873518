.adDisclosureActive {
  .ad-disclosure-cta {
    bottom: -40px;
    right: 0;
    z-index: -1;
    transition: bottom 300ms;
  }
  .min .ad-disclosure-cta {
    bottom: 40px !important;
  }
  .page-body {
    margin-top: 40px;
  }
  .banner-section .hero.sweeps {
    margin-top: 0px;
  }
  .home-page {
    padding-top: 0px;
  }
}
  .adDisclosureModal {
    height:auto;
    left:0;
    right:0;
    width: 730px;
    z-index:99999;
    margin:auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 #0a16461f;
    -webkit-box-shadow: 0 4px 8px 0 #0a16461f;
    -moz-box-shadow: 0 4px 8px 0 #0a16461f;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;

    .modal-header {
      border-bottom: none;
    }
    .modal-footer {
      border-top: none;
    }
  }
  .help-disclosure-page {
    color: #666;
  }
  .adDisclosureModal p {
    margin-bottom: 10px;
  }
  .pad-48-t {
    padding-top: 48px;
  }
  .pad-32 {
    padding: 32px;
  }
  .pad-24-t {
    padding-top: 24px;
  }
  .pad-32-b {
    padding-bottom: 32px;
  }
  .mar-4-tb{
    margin-bottom: 4px;
    margin-top: 4px;
  }
  .ad-list-view {
    background-color: #fff;
    border-radius: 16px;
    padding: 8px;
    width: 150px;
    padding-right:0;
  }
  .ad-list-view:hover {
    background-color: #F4F6FD;
  }
  .ad-list-view:active {
    background-color: #e4e9f5;
  }
  .mar-12-tb {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .mwview {
    .ad-disclosure-cta {
      right: 0;
      margin-top: 4px;
      margin-bottom: 4px;
      transition: opacity 1s;
      bottom: auto;
      z-index: 1;
    }
    .ad-disclosure-cta.hide {
      opacity: 0;
    }
    .adDisclosureModal {
      width: 100%;
      display: block;
      .modal-header {
        background: transparent;
      }
      .modal-content {
        border: none;
      }
      h2 {
        font-size: 24px;
        line-height: 32px;
        color: #252525;
        font-weight: 900;
      }
      .button.primary {
        width: 100%;
      }
    }
    .ad-list-view {
      display: inline-block;
      padding-right: 0;
    }
  }

body.is-tablet.ios.adDisclosureActive .eb-modal {
  position: relative !important; //override css in ebates-tablet.scss
}



